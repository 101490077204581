import React from 'react';
import { useIntl } from 'react-intl';

import { useFelaEnhanced } from '@ackee/fela';

import { DynamicContainer, ContainerType, Loader } from '../../../ui';

import * as felaRules from './CookiesPage.rules';

const createMarkup = content => ({
    __html: content,
});

export const CookiesPage = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    const { formatMessage } = useIntl();

    const content = formatMessage({ id: 'cookies.page.content' });

    const [loading, setLoading] = React.useState();

    React.useLayoutEffect(() => {
        function checkIfCookiesReady() {
            if (window.injectCookiesSettings) {
                // external script
                window.injectCookiesSettings();

                clearInterval(interval);

                setLoading(false);
            }
        }

        const interval = setInterval(checkIfCookiesReady, 2000);

        return () => clearInterval(interval);
    }, []);

    return (
        <DynamicContainer
            extend={rules.extendMainContainer}
            type={ContainerType.GRID}
            as={ContainerType.SECTION}
            isFluid
        >
            <DynamicContainer extend={rules.extendContentContainer} columns={6}>
                <div
                    id="dangerous-content"
                    className={styles.container}
                    dangerouslySetInnerHTML={createMarkup(content)}
                />
                <div id="external-cookies-settings" />

                <Loader extend={{ loader: rules.loader }} show={loading} />
            </DynamicContainer>
        </DynamicContainer>
    );
};
