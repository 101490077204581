import bulletImg from '../../../../../static/images/bullet.svg';

export const container = () => ({
    '> p': {
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
    },

    '> ul': {
        listStyleImage: `url(${bulletImg})`,
        paddingLeft: '1.4rem',
        marginBottom: '2.25rem',
    },

    '> ul > li': {
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        marginBottom: '1.5rem',
        paddingLeft: '1rem',
    },

    '> ul > li > a': {
        color: '#0000FF',
        transition: 'cubic-bezier(0.4, 0.0, 0.2, 1) color 0.3s',
    },

    '> ul > li > a:hover': {
        color: '#FF00FF',
    },

    '> p > a': {
        color: '#0000FF',
        transition: 'cubic-bezier(0.4, 0.0, 0.2, 1) color 0.3s',
    },

    '> p > a:hover': {
        color: '#FF00FF',
    },

    '> figure > img': {
        maxWidth: '100%',
    },
});

export const loader = () => ({
    position: 'relative',
});
